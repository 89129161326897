/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Arne Rief
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configRabus: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54789,
        enable: true,
        metaTitleSeperator: ' - ',
        metaTitleSuffix: 'Autohaus Rabus - Memmingen',
        projectName: 'Autohaus Rabus',
        searchPath: '/fahrzeugsuche',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    erweiterteSchnellsuche: {
        ...configDefault.erweiterteSchnellsuche,
        filter: ['typ', 'hersteller', 'modell', 'aktion'],
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            ...configDefault.fahrzeugsuche.filter,
            sidebar: ['sofort', 'tags', 'hersteller', 'modell', 'standort', 'aufbau', 'typ', 'km', 'preis', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]tags', '[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'standort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'ez', 'aus'],
        },
        hideHeading: false,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Figtree", sans-serif',
        fontSize: 16,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'color': '#ebebeb',
                            '&:hover': {
                                backgroundColor: '<colorSecondary>',
                                color: '#fff',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'color': '#ebebeb',
                            'fontWeight': 400,
                            'textTransform': 'uppercase',
                            '&:hover': {
                                backgroundColor: '<colorSecondary>',
                                boxShadow: 'none',
                                color: '#fff',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            'fontWeight': 400,
                            'textTransform': 'uppercase',
                            '&:not([disabled])': {
                                '&:hover': {
                                    borderColor: '<colorPrimary>',
                                    color: '<colorPrimary>',
                                },
                                '&:focus': {
                                    backgroundColor: 'transparent',
                                    borderColor: '<colorPrimary>',
                                    color: '<colorPrimary>',
                                },
                            },
                        },

                    },
                    soft: {
                        [getStyleQuery()]: {
                            'backgroundColor': '<colorSecondary>',
                            'color': '#fff',
                            'fontWeight': 400,
                            'textTransform': 'uppercase',
                            '&:hover': {
                                backgroundColor: '<colorPrimary>',
                                boxShadow: 'none',
                                color: '#ebebeb',
                            },
                            '&#detailview2_dialog_button_close': {
                                color: '#fff',
                            }
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                backgroundColor: 'transparent',
                                color: '<colorSecondary> !important',
                                // letterSpacing: 'unset !important',
                            },
                        },
                    },
                    textError: {
                        [getStyleQuery()]: {
                            'color': '#ff5630',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 86, 48, 0.08)',
                                color: '#ff5630',
                            },
                        },
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        '&[data-testid^="carTile_0_"]': {
                            backgroundColor: '#ebebeb',
                        },
                        '&[data-testid^="carTile_0_"] hr.MuiDivider-fullWidth': {
                            backgroundColor: '<colorPrimary>',
                            color: '<colorPrimary>',
                            marginLeft: '-24px',
                            marginRight: '-24px',
                        }
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        backgroundColor: '<colorPrimary>',
                        color: '#ebebeb',
                    },
                },
            },
            // ALL CARDS background #ebebeb
            // MuiDivider: {
            //     styleOverrides: {
            //         fullWidth: {
            //             backgroundColor: '<colorPrimary>',
            //             color: '<colorPrimary>',
            //         },
            //     },
            // },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'backgroundColor': '<colorSecondary>',
                            '&:hover': {
                                backgroundColor: '<colorPrimary>',
                                boxShadow: 'none',
                            },
                        },
                    },
                },
            },
            // EINZELANSICHT CAR DETAILS FOR #ebebeb background
            // MuiGrid2: {
            //     styleOverrides: {
            //         root: {
            //             [getStyleQuery()]: {
            //                 '&[data-testid="CarDetailsGrid_Container"] > div.MuiStack-root': {
            //                     borderColor: '<colorPrimary>',
            //                 },
            //             }
            //         },
            //     },
            // },
            MuiLink: {
                styleOverrides: {
                    underlineHover: {
                        [getStyleQuery()]: {
                            'textDecoration': 'underline',
                            '&:hover': {
                                color: '<colorSecondary>',
                            },
                        },
                    },
                },
            },
            // ALL CARDS background #ebebeb
            // MuiPaper: {
            //     styleOverrides: {
            //         root: {
            //             [getStyleQuery()]: {
            //                 '&:not([data-testid="CalcFdSummary_Card"])': {
            //                     'backgroundColor': '#ebebeb',
            //                 },
            //                 '&[data-testid="AusstattungLocationInfo_Card"] .MuiTypography-h5, &[data-testid="AusstattungLocationInfo_Card"] .MuiTypography-h6': {
            //                     color: '<colorPrimary>',
            //                 },
            //                 '&[data-testid="CalcFdRateOptions_Card"] > .MuiTypography-h5': {
            //                     color: '<colorPrimary>',
            //                 },
            //             },
            //         },
            //     },
            // },
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        [getStyleQuery()]: {
                            background: 'transparent',
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="FahrzeugSucheFilterSideBar_Container"], &[data-testid="FahrzeugSucheFilterTopArea_Container"]': { // filter bars all in white
                                '& .MuiCheckbox-root, .MuiSlider-root, .MuiSlider-markLabel, .MuiSelect-icon': {
                                    color: '<colorPrimary>',
                                },
                                '& .MuiFormControl-root': {
                                    border: '1px solid <colorPrimary>',
                                    color: '<colorPrimary>',
                                },
                                '& .MuiFormLabel-root, .MuiInputAdornment-root': {
                                    backgroundColor: '#fff',
                                    color: '<colorPrimary> !important',
                                },
                                '& .MuiInputBase-input': {
                                    '&[data-testid="RHFTextField_freier_text"]': {
                                        color: '<colorPrimary>',
                                    },
                                    '&[data-testid="RHFTextField_freier_text"]::placeholder': {
                                        color: '<colorPrimary>',
                                    },
                                },
                            },
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:focus': {
                                outline: 'none',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h5: {
                [getStyleQuery()]: {
                    textTransform: 'none',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    textTransform: 'none',
                },
            },
            subtitle1: {
                [getStyleQuery()]: {
                    textTransform: 'none',
                },
            },
        }),
        primaryColor: '#000000',
        secondaryColor: '#c40130',
        // light grey for black button text & Card background: '#ebebeb',
    },
};

export default configRabus;
