/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';

const configHenke: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30156,
        isGruppe: true,
        enable: true,
        metaTitleSuffix: 'SEAT Henke Automobile - Ihr Autohaus in Wedel / Hamburg',
        metaTitleSeperator: ' - ',
        projectName: 'Henke Automobile',
        searchPath: '/fahrzeuge',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 12,
        },
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    api: {
        ...configDefault.api,
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'standort', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'siegel', 'tags', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'km', 'standort', 'preis', 'rate', 'ez', 'siegel', 'tags', 'aus'],
        },
        hideHeading: false,
        useEyecatcher: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"verdana", "Helvetica", "Arial", sans-serif',
        fontSize: 15,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'div[data-testid="CarDetailsGrid_Container"] p.MuiTypography-body1': {
                        color: '#212b36 !important',
                    },
                    'div.UseContact_ContactTextButtonContainer': { // anti-transparency box around contact buttons
                        borderRadius: '100px !important',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'background': '<colorPrimary>',
                            'borderRadius': '100px',
                            'color': '#fff !important',
                            '&:hover': {
                                background: '#000',
                                boxShadow: 'none !important',
                                color: '#fff !important',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            borderRadius: '100px',
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            borderRadius: '100px',
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'color': '#fff !important',
                            '&:hover': {
                                background: '#000',
                                boxShadow: 'none !important',
                                color: '#fff !important',
                            },
                        },
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& > span.MuiRadio-root.MuiRadio-colorPrimary': { // checkmark "Finanzierung"/"Leasing"
                                color: '<colorPrimary>',
                            },
                            '&.Mui-disabled > h6.MuiTypography-subtitle1, &.Mui-disabled > p.MuiTypography-body2, &.Mui-disabled > span.MuiRadio-root.MuiRadio-colorPrimary': { // disabled "Finazierung"/"Leasing"
                                color: '#b2bbc4 !important',
                            },
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="LocationUserCard"]': {
                                marginTop: '20px',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CarPriceGrid_Container"] a.MuiIconButton-root': { // info button price
                                color: 'rgb(99, 115, 129)',
                            },
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.Mui-selected:hover': {
                                textDecoration: 'none',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    color: '#666666',
                },
            },
            button: {
                [getStyleQuery()]: {
                    fontWeight: 400,
                },
            },
        }),
        primaryColor: '#c31924',
    },
};

export default configHenke;
