import { TFunction } from 'i18next';
import { ConfigFilter, ConfigObject } from '../../../_configs/types';
import { Modules } from '../../../_json/modules';
import { Facet, Facets, Filter, SelectOption } from '../../../_types/Car';
import { RHFCheckbox, RHFCheckboxProps, RHFSelect, RHFSelectProps, RHFSlider, RHFSliderProps, RHFTextFieldProps } from '../../../components/hook-form';
import { IconifyProps } from '../../../components/iconify/types';
import { UseCarSearchReturn } from '../../../hooks/use-car-search/types';
import { stringToSlug } from '../../../utils/string-to-slug';

const checkUndefined = (e: any): e is undefined => e === undefined || e === 'undefined';

const checkUndefinedOrNull = (e: any): e is (undefined | null) => checkUndefined(e) || e === null || e === 'null';

const checkUndefinedNullOrEmpty = (e: any): e is (undefined | null) => {
    if (checkUndefinedOrNull(e)) return true;
    if (Array.isArray(e) && e.length < 1) return true;
    if (!Array.isArray(e) && typeof e === 'object' && Object.keys(e).length < 1) return true;
    if (!Array.isArray(e) && typeof e !== 'object' && (e === '' || e === '""')) return true;
    return false;
};

export const uniqueArray = (array: any[], unifyBy?: string): any[] => {
    const returnArr = array?.filter(function (value, index, self) {
        if (!checkUndefinedNullOrEmpty(unifyBy) && typeof value === 'object') {
            return index === self.findIndex((t) => (
                t[unifyBy] === value[unifyBy]
            ));
        }

        return self.indexOf(value) === index;
    }) || [];
    return returnArr;
};

export type FilterModules = Modules.ErweiterteSchnellsuche | Modules.Finanzierungsansicht | Modules.Fahrzeugsuche | Modules.Schnellsuche;

export type FilterObject = {
    facetKey: keyof Facets,
    filterKey: keyof Filter,
    icon: IconifyProps,
} & ({
    filterComponentProps?: unknown,
    type: 'aus',
} | {
    filterComponentProps?: Omit<RHFCheckboxProps, 'name' | 'options'>,
    type: 'check',
} | {
    filterComponentProps?: Omit<RHFSelectProps, 'name' | 'options'>,
    type: 'multiselect' | 'singleselect',
} | {
    filterComponentProps?: Omit<RHFSliderProps, 'label' | 'name' | 'options'>,
    type: 'range' | 'slider',
} | {
    filterComponentProps?: Omit<RHFTextFieldProps, 'name'>,
    type: 'number' | 'text',
});

export const arrAus: (translate: TFunction) => Facet[] = (translate) => {
    const arr: SelectOption[] = [
        // Innen
        { i: '36', t: translate('common:car.aus.innen.36'), group: translate('common:car.aus.innen.title') },
        { i: '27', t: translate('common:car.aus.innen.27'), group: translate('common:car.aus.innen.title') },
        { i: '1119', t: translate('common:car.aus.innen.1119'), group: translate('common:car.aus.innen.title') },

        { i: '278', t: translate('common:car.aus.innen.278'), group: translate('common:car.aus.innen.title') },
        { i: '22', t: translate('common:car.aus.innen.22'), group: translate('common:car.aus.innen.title') },
        { i: '81', t: translate('common:car.aus.innen.81'), group: translate('common:car.aus.innen.title') },

        { i: '29', t: translate('common:car.aus.innen.29'), group: translate('common:car.aus.innen.title') },
        { i: '23', t: translate('common:car.aus.innen.23'), group: translate('common:car.aus.innen.title') },
        { i: '24', t: translate('common:car.aus.innen.24'), group: translate('common:car.aus.innen.title') },

        // Aussen
        { i: '1140', t: translate('common:car.aus.aussen.1140'), group: translate('common:car.aus.aussen.title') },
        { i: '88', t: translate('common:car.aus.aussen.88'), group: translate('common:car.aus.aussen.title') },
        { i: '238', t: translate('common:car.aus.aussen.238'), group: translate('common:car.aus.aussen.title') },

        { i: '41', t: translate('common:car.aus.aussen.41'), group: translate('common:car.aus.aussen.title') },
        { i: '71', t: translate('common:car.aus.aussen.71'), group: translate('common:car.aus.aussen.title') },
        { i: '34', t: translate('common:car.aus.aussen.34'), group: translate('common:car.aus.aussen.title') },

        // Assistenz
        { i: '332', t: translate('common:car.aus.assistenz.332'), group: translate('common:car.aus.assistenz.title') },
        { i: '26', t: translate('common:car.aus.assistenz.26'), group: translate('common:car.aus.assistenz.title') },
        { i: '514', t: translate('common:car.aus.assistenz.514'), group: translate('common:car.aus.assistenz.title') },

        { i: '25', t: translate('common:car.aus.assistenz.25'), group: translate('common:car.aus.assistenz.title') },
        { i: '402', t: translate('common:car.aus.assistenz.402'), group: translate('common:car.aus.assistenz.title') },
        { i: '1145', t: translate('common:car.aus.assistenz.1145'), group: translate('common:car.aus.assistenz.title') },

        { i: '515', t: translate('common:car.aus.assistenz.515'), group: translate('common:car.aus.assistenz.title') },
        { i: '48', t: translate('common:car.aus.assistenz.48'), group: translate('common:car.aus.assistenz.title') },

        // Sonstiges
        { i: '61', t: translate('common:car.aus.sonstiges.61'), group: translate('common:car.aus.sonstiges.title') },
    ];

    return arr?.map((el) => ({ ...el, tSlug: stringToSlug(el?.t) }));
};

export const sStrFilter: (isGruppe: boolean) => Record<ConfigFilter, FilterObject> = (isGruppe) => ({
    'aktion': { facetKey: null, filterKey: 'aktion', type: 'check', icon: 'mdi:sale' },
    'aufbau': { facetKey: 'aufbau', filterKey: 'aufbau', type: 'singleselect', icon: 'mdi:car-convertible' },
    'aufbauten': { facetKey: 'aufbauten', filterKey: 'aufbauten', type: 'multiselect', icon: 'mdi:car-convertible' },
    'aus': { facetKey: 'aus', filterKey: 'ia', type: 'multiselect', icon: 'mdi:playlist-check' },
    'ez': { facetKey: 'ez', filterKey: 'EZ', type: 'range', icon: 'mdi:calendar-today' },
    'ezVon': { facetKey: 'ezSelect', filterKey: 'EZVon', type: 'singleselect', icon: 'mdi:calendar-today' },
    'ezBis': { facetKey: 'ezSelect', filterKey: 'EZBis', type: 'singleselect', icon: 'mdi:calendar-today' },
    'farbe': { facetKey: 'farbenAll', filterKey: 'Farben', type: 'singleselect', icon: 'mdi:palette' },
    'fd': { facetKey: 'fdAll', filterKey: 'fd', type: 'multiselect', icon: 'mdi:cash-100' },
    'getriebe': { facetKey: 'getriebe', filterKey: 'Getriebe', type: 'singleselect', icon: 'mdi:car-shift-pattern' },
    'hersteller': { facetKey: 'hersteller', filterKey: 'hersteller0', type: 'singleselect', icon: 'mdi:car' },
    'ignoreFirma': { facetKey: null, filterKey: 'ignoreFirma', type: 'multiselect' },
    'ignoreStandort': { facetKey: null, filterKey: 'ignoreStandort', type: 'multiselect' },
    'kategorie': { facetKey: 'kategorie', filterKey: 'kategorie', type: 'multiselect', icon: 'mdi:rv-truck' },
    'km': { facetKey: 'km', filterKey: 'KM', type: 'range', filterComponentProps: { einheit: 'km', step: 500 }, icon: 'mdi:counter' },
    'kmVon': { facetKey: 'kmSelect', filterKey: 'KMVon', type: 'singleselect', icon: 'mdi:counter' },
    'kmBis': { facetKey: 'kmSelect', filterKey: 'KMBis', type: 'singleselect', icon: 'mdi:counter' },
    'leistung': { facetKey: 'leistung', filterKey: 'Leistung', type: 'range', filterComponentProps: { einheit: 'kW' }, icon: 'mdi:performance' },
    'leistungVon': { facetKey: 'leistungSelect', filterKey: 'LeistungVon', type: 'singleselect', icon: 'mdi:performance' },
    'leistungBis': { facetKey: 'leistungSelect', filterKey: 'LeistungBis', type: 'singleselect', icon: 'mdi:performance' },
    'modell': { facetKey: 'modell', filterKey: 'modell0', type: 'singleselect', icon: 'mdi:tag' },
    'motor': { facetKey: 'MotorG', filterKey: 'motoren', type: 'multiselect', icon: 'mdi:engine' },
    'nfzHersteller': { facetKey: null, filterKey: 'nfzHersteller', type: 'switch' },
    'NFZ': { facetKey: null, filterKey: 'NFZ', type: 'switch' },
    'page': { facetKey: null, filterKey: 'page', type: 'number' },
    'pageSize': { facetKey: null, filterKey: 'pageSize', type: 'number' },
    'preis': { facetKey: 'preis', filterKey: 'Preis', type: 'range', filterComponentProps: { einheit: '€', step: 100 }, icon: 'mdi:currency-eur' },
    'preisVon': { facetKey: 'preisSelect', filterKey: 'PreisVon', type: 'singleselect', icon: 'mdi:currency-eur' },
    'preisBis': { facetKey: 'preisSelect', filterKey: 'PreisBis', type: 'singleselect', icon: 'mdi:currency-eur' },
    'rate': { facetKey: 'rate', filterKey: 'Rate', type: 'range', filterComponentProps: { einheit: '€', step: 10 }, icon: 'material-symbols:30fps-select' },
    'rateVon': { facetKey: 'rateSelectAll', filterKey: 'RateVon', type: 'singleselect', icon: 'material-symbols:30fps-select' },
    'rateBis': { facetKey: 'rateSelectAll', filterKey: 'RateBis', type: 'singleselect', icon: 'material-symbols:30fps-select' },
    'siegel': { facetKey: 'siegel', filterKey: 'siegel', type: 'singleselect', icon: 'mdi:shield-car' },
    'spacer': undefined,
    'sofort': { facetKey: null, filterKey: 'sofort', type: 'check', icon: 'mdi:map-marker-check' },
    'standort': { facetKey: isGruppe ? 'firmen' : 'standort', filterKey: isGruppe ? 'idFirma' : 'Standort', type: 'multiselect', icon: 'mdi:map-marker' },
    'StandortQuelle': { facetKey: null, filterKey: 'StandortQuelle', type: 'multiselect' },
    'tags': { facetKey: 'tags', filterKey: 'tags', type: 'singleselect', icon: 'mdi:sale' },
    'typ': {
        facetKey: 'bereich', filterKey: 'akttypList', type: 'multiselect', icon: 'mdi:train-car', filterComponentProps: {
            manipulateValueBeforeSend(origVal, newValueOrig, options) {
                let firedItem: { item: (string | number), state: boolean };
                const newValue = !checkUndefinedOrNull(newValueOrig?.props?.value) ? newValueOrig?.props?.value : undefined;
                const newValueOption = options?.find((o) => o?.i === newValue);
                const newValueState: (string | number)[] = Object.assign([], origVal);
                if (!checkUndefinedNullOrEmpty(newValueOption)) {
                    if (newValueState.includes(newValueOption.i)) {
                        newValueState.splice(newValueState.indexOf(newValueOption.i), 1);
                        firedItem = { item: newValue, state: false };
                    } else {
                        newValueState.push(newValueOption.i);
                        firedItem = { item: newValue, state: true };
                    }
                }
                if (!checkUndefinedNullOrEmpty(firedItem) && firedItem.item === 2 && firedItem.state === true) {
                    const additionalArr = options.filter((e) => e.i as number > 2).reduce((prev, curr) => [...prev, curr?.iSend || curr?.i], []);
                    return uniqueArray([...newValueState, ...additionalArr]);
                }

                return newValueState;
            },
        },
    },
    'volltext': { facetKey: null, filterKey: 'freier_text', type: 'text', icon: 'mdi:magnify' },
});

export function getFilterElement(fKey: string, results: UseCarSearchReturn['results'], settings: ConfigObject, translate: TFunction, disableIcon: boolean) {
    const filterElements = sStrFilter(settings?.general?.isGruppe);
    const f = fKey.replace('[SM]', '').replace('[LG]', '') as ConfigFilter;
    const filterElement = filterElements?.[f];

    if (f === 'spacer') {
        return null;
    }

    return (filterElement?.type === 'check'
        ? <RHFCheckbox
            {...filterElement?.filterComponentProps}
            key={`filterElement_${filterElement?.type}_${filterElement?.filterKey}`}
            icon={disableIcon === true ? undefined : filterElement?.icon}
            label={f === 'sofort' && !checkUndefinedNullOrEmpty(settings?.fahrzeugsuche?.customNameSofort)
                ? settings?.fahrzeugsuche?.customNameSofort
                : translate(`common:filter.${f}`)
            }
            name={filterElement?.filterKey}
            outlined
            uncheckIsNull
        />
        : (filterElement?.type === 'singleselect' || filterElement?.type === 'multiselect')
            ? <RHFSelect
                {...filterElement?.filterComponentProps}
                key={`filterElement_${filterElement?.type}_${filterElement?.filterKey}`}
                icon={disableIcon === true ? undefined : filterElement?.icon}
                label={translate(`common:filter.${f}`)}
                name={filterElement?.filterKey}
                options={filterElement?.type === 'multiselect'
                    ? results?.facets?.[filterElement?.facetKey]
                    : [{ i: -100, t: translate('common:filter.alle') }, ...(results?.facets?.[filterElement?.facetKey] || [])]
                }
                placeholder={translate('common:filter.alle')}
                multiple={filterElement?.type === 'multiselect'}
            />
            : filterElement?.type === 'range'
                ? <RHFSlider
                    {...filterElement?.filterComponentProps}
                    key={`filterElement_${filterElement?.type}_${filterElement?.filterKey}`}
                    icon={disableIcon === true ? undefined : filterElement?.icon}
                    label={translate(`common:filter.${f}`)}
                    name={filterElement?.filterKey}
                    options={results?.facets?.[filterElement?.facetKey]}
                />
                : `unknown filter: ${f}`
    );
}
