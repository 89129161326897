/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';
import { FilterOrder, FilterSort } from '../../_types/Car';

const configDieAutohausGruppeSR: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30008,
        isGruppe: true,
        enable: true,
        projectName: 'Die Autohaus Gruppe',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        buttons: {
            ...configDefault.contact.buttons,
            position: 'left',
            offsetY: 10,
        },
    },
    api: {
        ...configDefault.api,
        itTectureUcl: {
            // Autohaus am Regenstein
            // 10590: {
            //     vw: 'vw_40649',
            // },
            // Autohaus Bergmann
            // 53940: {
            //     cupra: 'cupra_81081',
            //     seat: 'seat_81081',
            // },
            // Autohaus Halbac
            // 51910: {
            //     cupra: 'cupra_81086',
            //     seat: 'seat_81086',
            //     vw: 'vw_22546',
            // },
            // Autohaus Osterwieck
            // 10600: {
            //     vw: 'vw_40088',
            // },
            // Autohaus Quedac
            // 10570: {
            //     vw: 'vw_78557',
            // },
            // Autohaus Wernigerode
            10170: {
                audi: 'audi_79537',
                skoda: 'skoda_53031',
                vw: 'vw_79537',
            },
        },
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        defaultSort: { order: FilterOrder.ASC, sort: FilterSort.Hersteller },
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'standort', 'preis', 'rate', 'ez', 'aus'],
        },
        hideContactPersons: true,
        useLocationMap: true,
    },
    style: {
        ...configDefault.style,
        fontFamily: '"audi-normal"',
        muiComponents: () => ({
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        [getStyleQuery()]: {
                            '& > legend > span': {
                                fontSize: '1.03em !important',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h1: {
                [getStyleQuery()]: {
                    fontFamily: '"audi-font"',
                    fontWeight: 900,
                },
            },
            h2: {
                [getStyleQuery()]: {
                    fontFamily: '"audi-font"',
                    fontWeight: 900,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"audi-font"',
                    fontWeight: 900,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"audi-font"',
                    fontWeight: 900,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"audi-font"',
                    fontWeight: 900,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"audi-font"',
                    fontWeight: 900,
                },
            },
        }),
        primaryColor: '#fab222',
    },
};

export default configDieAutohausGruppeSR;
